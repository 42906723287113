const initState = {
    socket: null,
    isConnected: false,
    isJoinedInstanceRoom: false,
    lastUpdateStatus: null,
    lastUpdateList: null,
    agentARNList: [],
    agentStatus: [],
    agentList: [],
    realtimeDataSocketInstance: null
}

/**
 * The reducer function that stores the Socket IO connection values in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const socketReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_SOCKET_IO":
            return {
                ...state,
                socket: action.payload
            }

        case "SET_SOCKET_CONNECTED":
            return {
                ...state,
                isConnected: action.payload
            }

        case "SET_AGENT_ARN_LIST":
            return {
                ...state,
                agentARNList: action.payload
            }

        case "SET_SOCKET_AGENT_LIST":
            const newAgentList = [...state.agentList]
            const newAgentARNList = [...state.agentARNList]
            action.payload.map(agent => {
                if (!state.agentARNList.includes(agent.agent_arn)) {
                    newAgentList.push(agent)
                    newAgentARNList.push(agent.agent_arn)
                }
            })

            console.log(">>> SET_SOCKET_AGENT_LIST :", newAgentList)

            return {
                ...state,
                agentList: newAgentList,
                agentARNList: newAgentARNList
            }

        case "SET_SOCKET_AGENT_STATUS":
            return {
                ...state,
                agentStatus: action.payload
            }

        case "SET_JOINED_INSTANCE_ROOM":
            return {
                ...state,
                isJoinedInstanceRoom: action.payload
            }

        case "SET_LAST_UPDATE_AGENT_STATUS":
            return {
                ...state,
                lastUpdateStatus: action.payload
            }

        case "SET_LAST_UPDATE_AGENT_LIST":
            return {
                ...state,
                lastUpdateList: action.payload
            }

        case "SET_SOCKET_INSTANCE":
            return {
                ...state,
                realtimeDataSocketInstance: action.payload
            }

        default:
            return state
    }
}

export default socketReducer