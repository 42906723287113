import React, { useEffect } from 'react'

// This will be defined in a nested component
let handleUnloadCallback = null

export const setUnloadCallback = (callback) => {
  handleUnloadCallback = callback
}

const withUnload = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      const handleUnload = (event) => {
        if (handleUnloadCallback) {
          handleUnloadCallback(event)
        }
      }

      window.addEventListener('unload', handleUnload)

      return () => {
        window.removeEventListener('unload', handleUnload)
      }
    }, [])

    return <WrappedComponent {...props} />
  }
}

export default withUnload
