// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chatReducer from './chat'
import connectReducer from './connect'
import taskReducer from './task'
import instanceIdReducer from './instance'
import widgetReducer from './widget'
import gridLayoutReducer from './grid'
import socketReducer from './socket'
import UIReducer from './UI'
import email from '@src/views/apps/email/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat: chatReducer,
  connect: connectReducer,
  task: taskReducer,
  instance: instanceIdReducer,
  widget: widgetReducer,
  gridLayout: gridLayoutReducer,
  socket: socketReducer,
  email,
  UI: UIReducer
})

export default rootReducer
