// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
/* const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
} */

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: themeConfig.layout.menu.isCollapsed,
  menuHidden: themeConfig.layout.menu.isHidden,
  rightSideMenuHidden: false,
  rightSideMenuTab: '1',
  layoutExtraWidth: 0,
  contentWidth: themeConfig.layout.contentWidth,
  theme: themeConfig.layout.skin,
  isLoading: true,
  currentPath: null,
  isWisdomOpened: true,
  dashboardsPaths: ['/home'],
  dashboardsLayout: {
    '/home': []
  },
  dashboardsNames: {
    '/home': 'Home'
  },
  dashboardsEditMode: {
    '/home': false
  }
}

/**
 * The reducer function that handles the layout values in Redux store
 * @param {Object} state the state object 
 * @param {Object} action the action object
 * @returns {Object}
 */
const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
      return { ...state, contentWidth: action.value }
    case 'HANDLE_MENU_COLLAPSED':
      //window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    case 'HANDLE_MENU_HIDDEN':
      return { ...state, menuHidden: action.value }
    case 'SET_Right_MENU_HIDDEN':
      return { ...state, rightSideMenuHidden: action.value }
    case 'SET_Right_MENU_TAB':
      return { ...state, rightSideMenuTab: action.value }
    case 'SET_LAYOUT_EXTRA_WIDTH':
      return { ...state, layoutExtraWidth: action.value }
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value }
    case 'SET_THEME_MODE':
      return { ...state, theme: action.value }
    case 'SET_LOADING':
      return { ...state, isLoading: action.value }
    case 'SET_CURRENT_PATH':
      return { ...state, currentPath: action.value }
    case 'SET_WISDOM_OPEN':
      return { ...state, isWisdomOpened: action.value }
    case 'SET_DASHBOARDS_PATHS':
      return { ...state, dashboardsPaths: [...state.dashboardsPaths, action.value] }
    case 'SET_DASHBOARDS_LAYOUTS':
      return { 
        ...state, 
        dashboardsLayout: {
          ...state.dashboardsLayout,
          [action.value.pathname] : action.value.layout
        }
      }
    case 'SET_DASHBOARDS_NAMES':
      return { 
        ...state, 
        dashboardsNames: {
          ...state.dashboardsNames, 
          ...action.value
        } 
      }
      
    case 'EDIT_DASHBOARDS_NAMES':
      return { 
        ...state, 
        dashboardsNames: {
          ...state.dashboardsNames, 
          [action.value.path]: action.value.name 
        } 
      }

    case 'DELETE_DASHBOARD':
      const { [action.value]: deletedName, ...restNames } = state.dashboardsNames
      const { [action.value]: deletedLayout, ...restLayouts } = state.dashboardsLayout
      return { 
        ...state, 
        dashboardsPaths: state.dashboardsPaths.filter(path => action.value !== path),
        dashboardsNames: { ...restNames },
        dashboardsLayout: { ...restLayouts }
      }

    case 'CHANGE_DASHBOARDS_MODE':
      return { 
        ...state, 
        dashboardsEditMode: {
          ...state.dashboardsEditMode,
          [action.value.path]: action.value.editMode
        }
      }
      
    default:
      return state
  }
}

export default layoutReducer
