// ** Initial State
const initialState = {
  mails: [
    {
      id: 1,
      from: {
        email: 'tommys@mail.com',
        name: 'Tommy Sicilia',
        avatar: require('@src/assets/images/avatars/1.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com'
        }
      ],
      subject: 'Theme Update',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John,</p><p>Biscuit lemon drops marshmallow. Cotton candy marshmallow bear claw. Dragée tiramisu cookie cotton candy. Carrot cake sweet roll I love macaroon wafer jelly soufflé I love dragée. Jujubes jelly I love carrot cake topping I love. Sweet candy I love chupa chups dragée. Tart I love gummies. Chocolate bar carrot cake candy wafer candy canes oat cake I love. Sesame snaps icing pudding sweet roll marshmallow. Cupcake brownie sweet roll chocolate bar I love gummies. Biscuit biscuit macaroon sesame snaps macaroon icing I love soufflé caramels. Apple pie candy jelly. I love icing gummi bears jelly-o pie muffin apple pie.</p><p>Marshmallow halvah brownie cake marzipan ice cream marshmallow. I love lollipop toffee croissant liquorice wafer muffin. Lollipop jelly beans caramels lollipop tootsie roll pudding pie macaroon tootsie roll. Oat cake jujubes gummies cake cake powder cupcake soufflé muffin. Chocolate caramels muffin tart. Jelly beans caramels dessert cotton candy liquorice chocolate cake. Chupa chups muffin bear claw I love. Biscuit jujubes soufflé tart caramels pie sugar plum. Croissant jelly beans cake. Ice cream chocolate liquorice dessert cookie chocolate cake. Powder tart sweet roll macaroon croissant. Sweet tootsie roll macaroon gummi bears macaroon. Gingerbread cake tart.</p><p>Regrads,</p><p>Kristeen Sicilia</p>',
      attachments: [
        {
          fileName: 'log.txt',
          thumbnail: require('@src/assets/images/icons/txt.png').default,
          url: '',
          size: '5mb'
        },
        {
          fileName: 'performance.xls',
          thumbnail: require('@src/assets/images/icons/xls.png').default,
          url: '',
          size: '10mb'
        }
      ],
      isStarred: false,
      labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true
    },
    {
      id: 2,
      from: {
        email: 'tressag@mail.com',
        name: 'Tressa Gass',
        avatar: require('@src/assets/images/avatars/2.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com'
        }
      ],
      subject: 'Company Report',
      cc: ['vrushankbrahmshatriya@mail.com'],
      bcc: ['menka@mail.com'],
      message:
        ' <p>Hello John,</p><p>Chocolate bar chupa chups sweet roll chocolate muffin macaroon liquorice tart. Carrot cake topping jelly-o cupcake sweet apple pie jelly I love. Chocolate cake I love dessert carrot cake tootsie roll chocolate I love. Tootsie roll pie marzipan sesame snaps. Lemon drops cake I love chocolate I love. Cheesecake croissant croissant cheesecake tootsie roll chocolate cake caramels. I love I love cake jelly. Pastry jelly beans I love I love. I love tootsie roll gummies toffee I love cookie cookie. Brownie tart croissant.</p><p>I love jelly pie. Croissant jelly beans lemon drops oat cake. Cupcake carrot cake ice cream cake cookie jelly-o fruitcake jelly macaroon. Biscuit muffin cupcake chocolate bar lollipop danish.</p><p>Ice cream I love I love lemon drops cotton candy macaroon. Jujubes soufflé oat cake sweet roll bear claw cotton candy cookie toffee tootsie roll. Danish sesame snaps sesame snaps cookie jelly danish chocolate bar.</p><p>Fruitcake pastry bear claw chocolate cookie. Biscuit icing bear claw. Sweet roll liquorice jelly-o muffin toffee I love wafer. Candy pastry halvah. Pie pastry candy canes tart. Fruitcake I love liquorice icing sweet roll macaroon. I love ice cream jelly-o biscuit brownie marzipan topping pudding sesame snaps. Chupa chups brownie brownie cotton candy sugar plum toffee. Tiramisu tootsie roll gingerbread biscuit I love ice cream brownie sesame snaps. I love croissant I love I love brownie pie marshmallow.</p><p>Regrads</p><p>Tressa Gass</p>',
      attachments: [],
      isStarred: true,
      labels: ['company', 'private'],
      time: 'Mon Dec 10 2018 07:55:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true
    },
    {
      id: 3,
      from: {
        email: 'hettiem@mail.com',
        name: 'Hettie Mcerlean',
        avatar: require('@src/assets/images/avatars/3.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com'
        },
        {
          name: 'Pixinvent',
          email: 'hello@pixinvent.net'
        }
      ],
      subject: 'Order Delivered',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John,</p><p>Chocolate bar chupa chups sweet roll chocolate muffin macaroon liquorice tart. Carrot cake topping jelly-o cupcake sweet apple pie jelly I love. Chocolate cake I love dessert carrot cake tootsie roll chocolate I love. Tootsie roll pie marzipan sesame snaps. Lemon drops cake I love chocolate I love. Cheesecake croissant croissant cheesecake tootsie roll chocolate cake caramels. I love I love cake jelly. Pastry jelly beans I love I love. I love tootsie roll gummies toffee I love cookie cookie. Brownie tart croissant.</p><p>I love jelly pie. Croissant jelly beans lemon drops oat cake. Cupcake carrot cake ice cream cake cookie jelly-o fruitcake jelly macaroon. Biscuit muffin cupcake chocolate bar lollipop danish.</p><p>Ice cream I love I love lemon drops cotton candy macaroon. Jujubes soufflé oat cake sweet roll bear claw cotton candy cookie toffee tootsie roll. Danish sesame snaps sesame snaps cookie jelly danish chocolate bar.</p><p>Fruitcake pastry bear claw chocolate cookie. Biscuit icing bear claw. Sweet roll liquorice jelly-o muffin toffee I love wafer. Candy pastry halvah. Pie pastry candy canes tart. Fruitcake I love liquorice icing sweet roll macaroon. I love ice cream jelly-o biscuit brownie marzipan topping pudding sesame snaps. Chupa chups brownie brownie cotton candy sugar plum toffee. Tiramisu tootsie roll gingerbread biscuit I love ice cream brownie sesame snaps. I love croissant I love I love brownie pie marshmallow.</p><p>Regrads</p><p>Hettie Mcerlean</p>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: 'Mon Dec 10 2018 08:35:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'spam',
      isRead: true
    },
    {
      id: 4,
      from: {
        email: 'louettae@mail.com',
        name: 'Louetta Esses',
        avatar: require('@src/assets/images/avatars/4.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@mail.com'
        }
      ],
      subject: 'Waterfall Model Update',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John,</p><p>Biscuit lemon drops marshmallow. Cotton candy marshmallow bear claw. Dragée tiramisu cookie cotton candy. Carrot cake sweet roll I love macaroon wafer jelly soufflé I love dragée. Jujubes jelly I love carrot cake topping I love. Sweet candy I love chupa chups dragée. Tart I love gummies. Chocolate bar carrot cake candy wafer candy canes oat cake I love. Sesame snaps icing pudding sweet roll marshmallow. Cupcake brownie sweet roll chocolate bar I love gummies. Biscuit biscuit macaroon sesame snaps macaroon icing I love soufflé caramels. Apple pie candy jelly. I love icing gummi bears jelly-o pie muffin apple pie.</p><p>Marshmallow halvah brownie cake marzipan ice cream marshmallow. I love lollipop toffee croissant liquorice wafer muffin. Lollipop jelly beans caramels lollipop tootsie roll pudding pie macaroon tootsie roll. Oat cake jujubes gummies cake cake powder cupcake soufflé muffin. Chocolate caramels muffin tart. Jelly beans caramels dessert cotton candy liquorice chocolate cake. Chupa chups muffin bear claw I love. Biscuit jujubes soufflé tart caramels pie sugar plum. Croissant jelly beans cake. Ice cream chocolate liquorice dessert cookie chocolate cake. Powder tart sweet roll macaroon croissant. Sweet tootsie roll macaroon gummi bears macaroon. Gingerbread cake tart.</p><p>Regrads,</p><p>Kristeen Sicilia</p>',
      attachments: [
        {
          fileName: 'report.doc',
          thumbnail: require('@src/assets/images/icons/doc.png').default,
          url: '',
          size: '32mb'
        }
      ],
      isStarred: false,
      labels: ['important'],
      time: 'Mon Dec 11 2018 09:04:10 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true
    },
    {
      id: 5,
      from: {
        email: 'bposvner0@zdnet.com',
        name: 'Bobbie Posvner',
        avatar: require('@src/assets/images/avatars/11.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@dot.gov'
        }
      ],
      subject: 'Re-contextualized zero administration toolset',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John,</p><p>Epiboly henbill hemichorea composedness resequent hearthpenny racialization freeboard Reboulia Albuginaceae Violales Trisagion resinlike plumlet insalubrious Urocoptis Orthoceratidae ogmic plateaux reinsist preconsumption rattener venipuncture Rhadamanthine</p><p>perversely corpusculated theophoric uncemented weiring myolipoma gudesire dramatics trichomaphyte adventitious video polychaete glossorrhaphy bestially zoospore nonnotional sawbones unprejudiciable wali collaborative coppled shammish manless predisorder</p>',
      attachments: [],
      isStarred: true,
      labels: ['private'],
      time: 'Tue Dec 12 2018 11:55:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'spam',
      isRead: true
    },
    {
      id: 6,
      from: {
        email: 'rgilder1@illinois.edu',
        name: 'Rebecca Gilder',
        avatar: require('@src/assets/images/avatars/6.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@google.co.uk'
        }
      ],
      subject: 'Integrated bi-directional help-desk',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>irretraceably indestructibility sisi fiddle pyretic Gerbera vocable hoped Chartreux thinglet whister fetiferous Guhayna overloud yuca suggestibly atmiatrics serioludicrous prehaustorium androgenic betulin chiropterite sprittail atrochal</p><p>stopper acinaceous bandcutter unprostituted undependableness Saffarid parasympathetic dishonorer embryogeny overscrupulous deicidal Tapuya roupily devotee alfet sophiologic hydrobromide onlook preverb Dottore primitial frostlike overbig Monocyclica</p>',
      attachments: [],
      isStarred: false,
      labels: ['personal'],
      time: 'Thu Dec 13 2018 08:25:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'trash',
      isRead: true
    },
    {
      id: 7,
      from: {
        email: 'swilby2@yandex.ru',
        name: 'Shawn Wilby',
        avatar: require('@src/assets/images/avatars/1.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@altervista.org'
        }
      ],
      subject: 'Devolved regional product',
      cc: [],
      bcc: [],
      message:
        '<p>Hello John, </p><p>scalpellum unisometrical emend esotrope gether separatical passionwise cringe flawful annotinous jiboa thrombotic essoinment successional backrope oligohemia gitaligenin formulaic bradycinesia paralgesic eumycetic receptoral eyrie yearly</p><p>atomistical semischolastic clour discardment pseudelytron cheson path Bennet Herat pinchem Tho extravagantly shaking procidence flocker amidoketone gyric Moi petrolific clink immunization estrepement spalder poitrail</p>',
      attachments: [],
      isStarred: false,
      labels: ['company'],
      time: 'Fri Dec 14 2018 04:49:23 GMT+0000 (GMT)',
      replies: [],
      folder: 'draft',
      isRead: true
    },
    {
      id: 8,
      from: {
        email: 'wmannering3@mozilla.org',
        name: 'Waldemar Mannering',
        avatar: require('@src/assets/images/avatars/11.png').default
      },
      to: [
        {
          name: 'me',
          email: 'johndoe@sciencedaily.com'
        }
      ],
      subject: 'Quality-focused methodical flexibility',
      cc: [],
      bcc: [],
      message:
        '<p>Hi John, </p><p>wartproof ketoheptose incomplicate hyomental organal supermaterial monogene sophister nizamate rightle multifilament phloroglucic overvehement boatloading derelictly probudgeting archantiquary unknighted pallograph Volcanalia Jacobitiana ethyl neth Jugatae</p><p>noumenalize irredential energeia phlebotomist galp dactylitis unparticipated solepiece demure metarhyolite toboggan unpleased perilaryngeal binoxalate rabbitry atomic duali dihexahedron Pseudogryphus boomboat obelisk undreaded unadmired podometer</p>',
      attachments: [],
      isStarred: false,
      labels: ['private'],
      time: 'Tue Dec 15 2018 11:02:28 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: false
    }
  ],
  params: {
    folder: "inbox",
    label: "",
    q: ""
  },
  currentMail: null,
  emailsMeta: {
    inbox: 3,
    draft: 4,
    spam: 2
  },
  selectedMails: []
}

const EmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MAILS':
      // ** If currentMail is not null / undefined then find and set currentMail
      let currMail = null
      if (state.currentMail !== null && state.currentMail !== undefined) {
        currMail = action.data.emails.find(i => i.id === state.currentMail.id)
      }
      return {
        ...state
      }
    case 'PAGINATE_MAIL':
      // ** Find current mail & add hasNextMail & hasPreviousMail props to current mail object based on index
      const data = action.data
      const dataIndex = state.mails.findIndex(i => i.id === data.id)
      dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
      dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
      return { ...state, currentMail: data }
    case 'UPDATE_MAILS':
      // ** Find and updated mail
      function updateMailData(email) {
        Object.assign(email, action.dataToUpdate)
      }
      state.mails.forEach(email => {
        if (action.emailIds.includes(email.id)) updateMailData(email)
      })
      return { ...state }
    case 'UPDATE_MAIL_LABEL':
      return { ...state }
    case 'SELECT_CURRENT_MAIL':
      return { ...state, currentMail: action.mail }
    case 'SELECT_MAIL':
      // ** Select mails
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.id)) {
        selectedMails.push(action.id)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.id), 1)
      }
      return { ...state, selectedMails }
    case 'SELECT_ALL_MAIL':
      // ** Select all mails
      const selectAllMails = []
      if (action.val) {
        selectAllMails.length = 0
        state.mails.forEach(mail => selectAllMails.push(mail.id))
      } else {
        selectAllMails.length = 0
      }
      return { ...state, selectedMails: selectAllMails }
    case 'RESET_SELECT_MAILS':
      // ** Reset(remove) all selected mails
      return { ...state, selectedMails: [] }
    default:
      return state
  }
}

export default EmailReducer
