const initState = {
  opened: false,
  dynamicPopUpData: {},
  isGroupContactHistoriesLoading: false,
  navbarColor: 'white'
}

const UIReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_DYNAMIC_POPUP_DATA':
      return {
        ...state,
        dynamicPopUpData: action.payload
      }
    case 'SET_DYNAMIC_POPUP_DATA_OPENED_STATE':
      return {
        ...state,
        opened: action.payload
      }
    case 'SET_IS_GROUP_CONTACT_HISTORIES_LOADING':
      return {
        ...state,
        isGroupContactHistoriesLoading: action.payload
      }
    case 'SET_NAVBAR_COLOR':
      return {
        ...state,
        navbarColor: action.payload
      }
    default:
      return state
  }
}
export default UIReducer
