// ** React Imports
import { Suspense, lazy, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { LoggerContainer } from 'logrock'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './@core/scss/base/plugins/extensions/ext-component-sweet-alerts.scss'
import './assets/scss/style.scss'

// **  Font Awesome global
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import withUnload from './hoc/withUnload' // Import your HOC

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon])

library.add(...iconList)

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import useIsMainWindow from './utility/hooks/useIsMainWindow'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
const AppWithUnload = withUnload(LazyApp) // Wrap LazyApp with the HOC

const Main = () => {
  useEffect(() => {
    window.localStorage.setItem('checkTab', [])
  }, [])

  // const loggerCtx = useContext(LoggerContext)
  // const isMain = useIsMainWindow()
  const showMessage = useCallback((level, message, important) => {
    if (important) {
      alert(message)
    }
  })
  return (
    <LoggerContainer
      sessionID={window.sessionID}
      limit={1000} // Stack length limit. On overflow, the first element will be removed
      // getCurrentDate={() => {
      //   // Critical error date
      //   return .format('YYYY-MM-DD HH:mm:ss')
      // }}
      stdout={showMessage} // Display some errors as a tooltip for users
      // onError={(stackData) => {
      //   // Send a stack of actions before the error to the backend (or otherwise process it)
      //   sendToServer(stack)
      // }}
      onPrepareStack={(stack) => {
        // Allows you to add additional information to the stack
        stack.language = window.navigator.language
      }}
    >
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <IntlProviderWrapper>
              <AppWithUnload />
              <ToastContainer newestOnTop />
            </IntlProviderWrapper>
          </ThemeContext>
        </Suspense>
      </Provider>
    </LoggerContainer>
  )
}
ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
