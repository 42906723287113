// import "amazon-connect-streams"
const initialState = {
  chatSession: false,
  chats: [],
  contacts: [],
  chatsContacts: [],
  userProfile: {},
  selectedUser: {},
  chatIds: [],
  msgToWisdom: ''
}

const addChatContacts = (state, action) => {
  const { chatsContacts } = state

  if (action.payload && action.payload !== 'undefined') {
    // Only add contact id if not exist yet
    if (!chatsContacts.includes(action.payload)) {
      chatsContacts[action.payload] = {}
    }
    console.log('>>> ADD_CHAT_CONTACTS :', chatsContacts)
    return { ...state, chatsContacts }
  }

  return state
}

const populateChatContacts = (state, action) => {
  const { chatsContacts } = state
  // Only add contact id if not exist yet
  if (action.payload.contactId) {
    if (!chatsContacts.includes(action.payload.contactId)) {
      // chatsContact.push(action.payload)
      chatsContacts[action.payload.contactId] = {
        cnn: action.payload.cnn,
        contact: action.payload.contact,
        chatSession: action.payload.agentChatSession
      }
    }
    // console.log(">>> POPULATE_CHAT_CONTACTS payload:", action.payload)
    console.log('>>> POPULATE_CHAT_CONTACTS :', chatsContacts)
    return { ...state, chatsContacts }
  }
  return state
}

const removeChatContacts = (state, action) => {
  const { chatsContacts, chats } = state

  // Remove contact id only if exist in state
  for (const iterator in chatsContacts) {
    if (iterator === action.payload) {
      delete chatsContacts[iterator]
    }
  }
  const newChats = chats.filter((v) => v.userId !== action.payload)
  return { ...state, chatsContacts, chats: newChats }
}

const setContacts = (state, action) => {
  const { chatsContacts, contacts } = state
  console.log('>>> SET_CONTACTS : state:', state)
  console.log('>>> SET_CONTACTS : action:', action)
  const newChatsContacts = chatsContacts
  const newContacts = [...contacts]

  // Only add contact id if not exist yet
  if (action.payload && !chatsContacts[action.payload.id]) {
    newContacts.push(action.payload)
    newChatsContacts[action.payload.id] = {}
  }
  return { ...state, contacts: newContacts, chatsContacts: newChatsContacts }
}

// New function to solve 2nd chat issue
const selectChat = (state, action) => {
  const { chats, contacts } = state

  console.log('>>> SELECT_CHAT: contacts:', contacts)
  console.log('>>> SELECT_CHAT: chats:', chats)
  // console.log(">>> ALL CONTACT FROM DISPATCHER:", contacts)

  // Get event id from URL
  const userId = action.data.id
  console.log('>>> SELECT_CHAT: userId:', userId)
  const chat = chats.find((c) => c.userId === userId)
  if (chat) chat.unseenMsgs = 0
  const contact = contacts.find((c) => c.id === userId)
  if (contact && contact.chat) contact.chat.unseenMsgs = 0

  // console.log(">>> Select User dispatcher:", contact)
  // alert("THIS")

  console.log('SELECT_CHAT dispatch >> Chat: ', chat)
  console.log('SELECT_CHAT dispatch >> Contact: ', contact)

  // console.log('>>> SELECT_CHAT:', action.data)
  return { ...state, selectedUser: { chat, contact } }
}

const removeContactAndChat = (state, action) => {
  const { chats, contacts } = state
  let newRemovedSelectedUser = state.selectedUser
  console.log('>>> selectedUser :', newRemovedSelectedUser)
  console.log('>>> action.payload :', action.payload)
  console.log('>>> contacts :', contacts)
  console.log('>>> chats :', chats)

  const removeContacts = contacts.filter((v) => v.id !== action.payload)
  const newChat = chats.filter((v) => v.userId !== action.payload)

  if (removeContacts && removeContacts.length) {
    if (newRemovedSelectedUser && newRemovedSelectedUser.contact && newRemovedSelectedUser.contact.id === action.payload) {
      // Get event id from URL
      const userId = removeContacts[0].id

      const chat = newChat.find((c) => c.id === userId)
      console.log('>>> REMOVE_CONTACTS : chat :', chat)
      if (chat) chat.unseenMsgs = 0
      const contact = contacts.find((c) => c.id === userId)
      console.log('>>> REMOVE_CONTACTS : contact :', contact)
      if (contact && contact.chat) contact.chat.unseenMsgs = 0

      console.log('>>> Set SELECTED USER not empty :', newRemovedSelectedUser)
      newRemovedSelectedUser = { chat, contact }
    } else {
      console.log('>>> Set SELECTED USER empty :', newRemovedSelectedUser)
      newRemovedSelectedUser = {}
    }
  } else {
    newRemovedSelectedUser = {}
    console.log('>>> Set SELECTED USER empty :', newRemovedSelectedUser)
  }
  console.log('>>> REMOVE_CONTACTS: ', removeContacts)
  console.log('>>> SELECTED_USER : ', newRemovedSelectedUser)
  console.log('>>> NEW_CHAT :', newChat)

  const newState = { ...state, contacts: removeContacts, selectedUser: newRemovedSelectedUser, chats: newChat }

  console.log('>>> NEW_STATE : ', newState)

  return newState
}

const addChat = (state, action) => {
  const { chats, selectedUser, contacts } = state

  console.log('>>> ADD CHAT >>> payload chats: ', action.payload)
  console.log('>>> ADD CHAT >>> chats1st: ', chats)
  console.log('>>> ADD CHAT >>> selectedUser1st: ', selectedUser)

  const userIndex = chats.findIndex((el) => el.userId === action.payload.userId)

  // eslint-disable-next-line prefer-const
  let updatedChats = [...chats]

  if (userIndex === -1) {
    updatedChats.push(action.payload)
  } else {
    const userObject = updatedChats[userIndex]
    const chatTranscript = userObject.chat
    const isChatExists = chatTranscript.filter((el) => el.id === action.payload.chat.id)

    console.log('>>> ADD CHAT >>> isChatExists: ', isChatExists)
    if (isChatExists.length && isChatExists.length > 0) {
      userObject.chat.push(action.payload.chat)
      updatedChats[userIndex] = { ...userObject }
      console.log('>>> ADD CHAT >>> inside the IF, updatedChats: ', updatedChats)
    }
  }

  if (selectedUser && selectedUser.contact && selectedUser.contact.id) {
    if (selectedUser.contact.id === action.payload.userId) {
      selectedUser.chat = userIndex === -1 ? updatedChats[0] : updatedChats[userIndex]
    }
  }

  console.log('>>> ADD CHAT >>> selectedUser: ', selectedUser)
  console.log('>>> ADD CHAT >>> updated chats: ', updatedChats)

  console.log('>>> contacts:', contacts)
  const newContacts = updatedChats.map((chat) => {
    const contact = contacts.find((c) => c.id === action.payload.userId)
    if (contact && selectedUser && selectedUser.chat) {
      contact.chat = {
        id: chat.userId,
        unseenMsgs: chat.unseenMsgs,
        lastMessage: selectedUser.chat[selectedUser.chat.length - 1]
      }
    } else {
      console.log('>>> addChat [contact]:', contact)
      console.log('>>> addChat [selectedUser]:', selectedUser)
    }
    return contact
  })
  console.log('>>> newContacts:', newContacts)
  console.log('>>> ADD CHAT >>> chats2nd: ', updatedChats)
  console.log('>>> ADD CHAT >>> selectedUser2nd: ', selectedUser)

  return { ...state, chats: updatedChats, selectedUser }
}

const loadAgentChat = (state, action) => {
  const { chats, selectedUser, contacts } = state
  console.log('>>> LOAD_AGENT_CHAT >>> selectedUser1st:', selectedUser)
  console.log('>>> LOAD_AGENT_CHAT >>> chats1st:', chats)
  const userIndex = chats.findIndex((el) => el.userId === action.payload.userId)

  if (userIndex === -1) {
    chats.push(action.payload)
  }

  if (selectedUser && selectedUser.contact && selectedUser.contact.id) {
    if (selectedUser.contact.id === action.payload.userId) {
      selectedUser.chat = userIndex === -1 ? chats[0] : chats[userIndex]
    }
  }

  const newContacts = chats.map((chat) => {
    const contact = contacts.find((c) => c.id === action.payload.userId)
    if (contact && selectedUser && chat.chat.length) {
      // console.log(">>> DEBUG newContacts [selectedUser.chat]:", selectedUser.chat)
      // console.log(">>> DEBUG newContacts [chat]:", chat)
      // console.log(">>> newContacts [item]:", item)
      contact.chat = { id: chat.userId, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    }
    return contact
  })
  console.log('>>> LOAD_AGENT_CHAT >>> selectedUser2nd:', selectedUser)
  console.log('>>> LOAD_AGENT_CHAT >>> chats2nd:', chats)
  return { ...state, chats, selectedUser }
}

const incomingChat = (state, action) => {
  // TODO Fix possible ERROR here ? {userId: undefined, senderId: undefined, chat: {…}}
  console.log('>>> INCOMING_CHAT:')
  console.info(action.payload)
  console.log('>>>INCOMING_CHAT >>> state:', state)

  const { chats, selectedUser, contacts } = state
  console.log('>>> INCOMING_CHAT >>> selectedUser1st:', selectedUser)
  const userChatIndex = chats.findIndex((el) => el.userId === action.payload.userId)

  if (userChatIndex !== -1) {
    const userObject = chats[userChatIndex]
    const chatTranscript = userObject.chat
    const isChatExists = chatTranscript.findIndex((el) => el.id === action.payload.chat.id)

    if (isChatExists === -1) {
      userObject.chat.push(action.payload.chat)

      const cleanedChat = userObject.chat.filter((el) => !Array.isArray(el))
      userObject.chat = cleanedChat

      chats[userChatIndex] = { ...userObject }
      console.log('>>> INCOMING_CHAT >>> SELECTED USER BEFORE THE IF: ', selectedUser)
      // ** CHANGE: uncommented these lines to push chat message in selectedUser
      if (selectedUser.contact && selectedUser.contact.id) {
        // ** Add 2nd condition to avoid duplicate message
        console.log('SELECTED USER LASt ITEM TIME: ', selectedUser.chat.chat[selectedUser.chat.chat - 1].time)
        console.log('PAYLOAD ITEM TIME: ', action.payload.chat.time)
        if (
          selectedUser.contact.id === action.payload.userId
          // &&
          // (!selectedUser.chat.chat[selectedUser.chat.chat - 1] || selectedUser.chat.chat[selectedUser.chat.chat - 1].time !== action.payload.chat.time)
        ) {
          selectedUser.chat.chat.push(action.payload.chat)
          // console.log(
          //   '>>> INCOMING_CHAT >>> INSIDE THE IF LOADING NEW MESSAGE: ',
          //   action.payload.chat.message,
          //   'IN SELECTED USER ARRAY CHAT CHAT: ',
          //   selectedUser.chat.chat
          // )
        }
      }
      console.log('>>> INCOMING_CHAT >>> SELECTED USER AFTER THE IF: ', selectedUser)
      console.log('>>> contacts:', contacts)

      const newContacts = chats.map((chat) => {
        const contact = contacts.find((c) => c.id === chat.userId)
        if (contact.id === action.payload.userId && selectedUser && selectedUser.chat.length) {
          contact.chat = { id: chat.userId, unseenMsgs: chat.unseenMsgs, lastMessage: selectedUser.chat[selectedUser.chat.length - 1] }
          //     console.log(">>> newContacts [contact.chat]:", contact.chat)
          // } else {
          //     console.log(">>> newContacts [contact]:", contact)
        }
        return contact
      })
      console.log('>>> newContacts:', newContacts)
    }
  }

  console.log('>>> INCOMING_CHAT >>> chats:', chats)
  console.log('>>> INCOMING_CHAT >>> selectedUser2nd:', selectedUser)

  return { ...state, chats, selectedUser }
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    // AWS related
    case 'SET_CHAT_SESSION':
      return { ...state, chatSession: action.payload }
      break
    case 'ADD_CHAT_CONTACTS':
      return addChatContacts(state, action)
      break
    case 'POPULATE_CHAT_CONTACTS':
      return populateChatContacts(state, action)
      break
    case 'REMOVE_CHAT_CONTACTS':
      return removeChatContacts(state, action)
      break

    // Chat Template related
    case 'SET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
      break
    case 'GET_CHAT_CONTACTS':
      return { ...state, contacts: action.data.contacts }
      break
    case 'SET_CONTACTS':
      return setContacts(state, action)
      break
    case 'REMOVE_CONTACT_AND_CHAT':
      return removeContactAndChat(state, action)
      break
    case 'SELECT_CHAT':
      // console.log('>>> SELECT_CHAT:', action.data)
      // return { ...state, selectedUser: action.data }
      return selectChat(state, action)
      break
    case 'SEND_MSG':
      //  ** Add new msg to chat
      const newMsg = action.chat
      return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
      break
    case 'ADD_CHAT': // from aws event
      return addChat(state, action)
      break
    case 'LOAD_AGENT_CHAT': // from aws event
      return loadAgentChat(state, action)
      break
    case 'INCOMING_CHAT':
      return incomingChat(state, action)
      break
    // Add to use Wisdom in chat
    case 'SET_MSG_TO_WISDOM':
      return { ...state, msgToWisdom: action.payload }
      break
    // return state
    default:
      return state
  }
}

export default chatReducer
