const initState = {
  glpiAuthToken: null,
  glpiAllTickets: [],
  glpiTicketsExtraFields: [],
  glpiFullTicketsList: [],
  chosenTicketDetails: null,
  chosenTicketFollowups: [],
  glpiUsersList: [],
  glpiTicketReply: null
}

/**
 * The reducer function that handles the GLPI Tasks related values in Redux store
 * @param {Object} state the state object
 * @param {Object} action the action object
 * @returns {Object}
 */
const taskReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_GLPI_AUTH_SESSION_TOKEN":
      return {
        ...state,
        glpiAuthToken: action.payload
      }
    case "SET_GLPI_ALL_TICKETS":
      return {
        ...state,
        glpiAllTickets: action.payload
      }
    case "SET_GLPI_TICKETS_EXTRA_FIELDS":
      return {
        ...state,
        glpiTicketsExtraFields: action.payload
      }
    case "SET_GLPI_FULL_TICKETS_LIST":
      return {
        ...state,
        glpiFullTicketsList: action.payload
      }
    case "SET_CHOSEN_TICKET_DETAILS":
      return {
        ...state,
        chosenTicketDetails: action.payload
      }
    case "SET_CHOSEN_TICKET_FOLLOWUPS":
      return {
        ...state,
        chosenTicketFollowups: action.payload
      }
    case "SET_GLPI_USERS_LIST":
      return {
        ...state,
        glpiUsersList: action.payload
      }
    case "SET_GLPI_TICKET_REPLY":
      return {
        ...state,
        glpiTicketReply: action.payload
      }
    default:
      return state
  }
}

export default taskReducer
